/**
 * monitor-grafana 添加auth 项目选择器 by liangyujian 20231115
 */
import React, { useEffect, useState } from 'react';

import { SelectableValue } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';
import { useMediaQueryChange } from 'app/core/hooks/useMediaQueryChange';
import { contextSrv } from 'app/core/services/context_srv';
import { loadTeams } from 'app/features/profile/state/actions';
import { setCookies } from 'app/monitor/utils';
import { useDispatch, useSelector } from 'app/types';

import { ProjectPicker } from './ProjectPicker';
import { ProjectSelect } from './ProjectSelect';
import { ProjectTeam } from './types';

export function ProjectSwitcher() {
  const theme = useTheme2();
  const dispatch = useDispatch();
  const teams = useSelector((state) => state.user.teams).map((item) => ({ name: item.name }));
  const onSelectChange = (option: SelectableValue<ProjectTeam>) => {
    if (option.value) {
      const project = option.value.name;
      setCookies({ AUTH_PROJECT: project });
      localStorage.setItem('authProjectCode', project);
      window.authProject = project;
      // TODO how to reload the current page
      window.location.reload();
    }
  };
  useEffect(() => {
    if (contextSrv.isSignedIn) {
      dispatch(loadTeams());
    }
  }, [dispatch]);

  const breakpoint = theme.breakpoints.values.sm;

  const [isSmallScreen, setIsSmallScreen] = useState(!window.matchMedia(`(min-width: ${breakpoint}px)`).matches);

  useMediaQueryChange({
    breakpoint,
    onChange: (e) => {
      setIsSmallScreen(!e.matches);
    },
  });

  const Switcher = isSmallScreen ? ProjectPicker : ProjectSelect;

  return <Switcher teams={teams} onSelectChange={onSelectChange} />;
}
