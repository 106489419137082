/** monitor-grafana
 * 嵌入配置
 * @author liangyujian 2022-09-19
 */
import { matchPath } from 'react-router-dom';

import grafanaConfig from 'app/core/config';
import { KioskMode } from 'app/types';

interface Config {
  kioskMode?: string;
  folders?: string[];
  dashboards?: string[];
}

interface PathConfig extends Config {
  pathname: string;
}

interface FrameConfig extends Config {
  origin: string;
  system: string;
  path?: PathConfig[];
}

export const frameConfigs: FrameConfig[] = [
  // monitor
  {
    origin: '^https?://((testing|staging)\\.|oversea-)?monitor(-office|-oversea)?\\.nie\\.(netease|easebar)\\.com',
    system: '',
    // 某个的 Pahtname 下的权限
    path: [
      {
        pathname: '/view/containerlist',
        kioskMode: KioskMode.Frame,
        folders: ['4aOa0jG4z'],
        dashboards: [],
      },
      {
        pathname: '/home',
        kioskMode: KioskMode.Frame,
        folders: [],
        dashboards: ['6cx8FFv41'],
      },
    ],
  },
  // monitor 测试环境
  {
    origin: '^https?://monitor3-[0-9a-z-]*\\.shipyard\\.nie\\.netease\\.com',
    system: 'monitor3',
    folders: [],
    dashboards: [],
  },
  // streamfly 正式、测试环境
  {
    origin: '^https?://(.*-)?streamfly\\.nie\\.(netease|easebar)\\.com',
    system: 'streamfly',
    folders: ['N2uo1qnVk', 'e35513ff-7891-44fc-8129-5727d53b4855'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  // streamfly 海外环境
  {
    origin: '^https?://streamfly.gdc.nie.easebar.com',
    system: 'streamfly',
    folders: ['N2uo1qnVk', 'e35513ff-7891-44fc-8129-5727d53b4855'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  // gaia
  {
    origin: '^https?://all-in-one-staging-d377c4b9\\.shipyard\\.nie\\.(netease|easebar)\\.com',
    system: 'gaia',
    folders: [],
    dashboards: ['ntS2ZL74z'],
    kioskMode: KioskMode.Frame,
  },
  // gaia
  {
    origin: '^https?://(gaia(-pre)?)|(gemini\\.sa)\\.nie\\.(netease|easebar)\\.com',
    system: 'gaia',
    folders: [],
    dashboards: ['ntS2ZL74z'],
    kioskMode: KioskMode.Frame,
  },
  // tmax
  {
    origin: '^https?://tmax(-300)?\\.nie\\.(netease|easebar)\\.com',
    system: 'tmax',
    folders: ['pJs5Ymk4k'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  // gamecloud
  {
    origin: '^https?://(test-|dev-|oversea-|oversea-office\\.)?gamecloud(-office|-it)?\\.nie\\.(netease|easebar)\\.com',
    system: 'gamecloud',
    folders: [
      'Mojca0v4k',
      'MJZuw_04k',
      '2uMUG8AVz',
      'LukIg6L4z',
      'o-OaUfS4k',
      '7ILLnPCVz',
      'H1x87984k',
      'S1G2LizVz',
      'jLYXv6A4z',
    ],
    dashboards: [],
    // gamecloud 系统需要隐藏变量，所以通过 url kiosk=tv 的方式 by 冯凯妍 20230728
    // kioskMode: KioskMode.Frame,
  },
  {
    origin: '^https?://(feature-test-(\\d+-)?)?skyline(\\.sa)?\\.nie\\.(netease|easebar)\\.com',
    system: 'gamecloud',
    folders: [
      'Mojca0v4k',
      'MJZuw_04k',
      '2uMUG8AVz',
      'LukIg6L4z',
      'o-OaUfS4k',
      '7ILLnPCVz',
      'H1x87984k',
      'S1G2LizVz',
      'jLYXv6A4z',
    ],
    dashboards: [],
    // gamecloud 系统需要隐藏变量，所以通过 url kiosk=tv 的方式 by 冯凯妍 20230728
    // kioskMode: KioskMode.Frame,
  },
  // devcloud
  {
    origin: '^https?://devcloud(-office)?\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: ['os2yUQO4k'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  {
    origin: '^https?://(svn2|flowx)\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: ['os2yUQO4k'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  {
    origin: '^https?://flowx-(testing|lyf)\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: ['os2yUQO4k'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  {
    origin: '^https?://gjxn1386-flowx-testing\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: ['os2yUQO4k'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  // cld
  {
    origin: '^https?://gon(-test)?\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: [],
    dashboards: ['4F9RtWb4z'],
    kioskMode: KioskMode.Frame,
  },
  // gdc
  {
    origin: '^https?://doris-manager\\.gdc\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: ['Xys0hIjVz'],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  // db
  {
    origin:
      '^https?://((test|pre|dev)-)?db(-(oversea|office|oc))?(-(test|pre|dev|office-test))?\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: [
      '8sneK7a4k', // ocean
      'xOKfuCA4k', // dumbo
      'E70lHYIVk', // dumbo
      'uTa3WVQ4k', // redis
      'ueo8qms4k', // etcdsaas
      'ab263791-20fc-48fc-81c9-5fec8a96afe5', // dep347
      'f224140a-8ee2-4867-b068-945f3aa9348c', // dep348
      'bc0f7374-dd6d-4388-87d6-0d9398de4b73', // oceantest
      'c70fd9f1-8f65-42c1-af19-980b61afa704', // dep349
      'e2d00c55-87a4-4274-902a-2293ec0aa58c', // dep350
      'e2aa9f5b-b355-4a74-b6b2-9dcd09d95140', // dep350
    ],
    dashboards: [],
    kioskMode: KioskMode.Frame,
  },
  // ddos
  {
    origin: '^https?://ddos(-test|-dev)?(\\.nie)?\\.(netease|easebar)\\.com',
    system: '',
    folders: ['e1d00f25-4bf3-471e-9824-7d305884559d', 'YfOeFxJVk'],
    dashboards: [],
  },
  // entrance
  {
    origin: '^https?://(.*)?entrance\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: ['be7cab06-d354-4df8-8f15-f3bb9d325809'],
    dashboards: [],
  },
  // 本地开发环境
  {
    origin: '^http://localhost',
    system: '',
    folders: [],
    dashboards: [],
  },
  {
    origin: '^http://127\\.0\\.0\\.1',
    system: '',
    folders: [],
    dashboards: [],
  },
  {
    origin: '^https?://myown\\.nie\\.(netease|easebar)\\.com',
    system: '',
    folders: [],
    dashboards: [],
  },
];

export function getFrameConfig(env = 'production', parentPathname?: string) {
  const { ancestorOrigins } = window.location;
  const config: Window['frameConfig'] = {
    allowed: false, // 默认不允许嵌套
    system: '',
    origin: '',
    pathname: '',
    folders: [],
    dashboards: [],
  };

  // 访问报告服务时跳过重定向
  const { pathname, search } = window.location;
  if (search.indexOf('render=1') !== -1 || pathname.startsWith('/render/') || grafanaConfig.allowOpenidLogin) {
    config.allowed = true;
    return config;
  }

  // 仅生产环境下判断
  if (env === 'production') {
    if (window.parent === window) {
      const project = window.authProject;
      // easebar 的域名跳转到 /oversea/grafana 路径
      const grafanaPathname = window.location.origin.includes('easebar') ? '/oversea/grafana' : '/grafana';
      const newUrl = `${grafanaConfig.redirectUrl}/_${project}${grafanaPathname}${pathname}${search}`;
      window.open(newUrl, '_self');
    } else if (ancestorOrigins?.length) {
      const ancestorOrigin = ancestorOrigins[0];
      const frameConfig = frameConfigs.find(
        (item) =>
          item.origin === ancestorOrigin ||
          (item.origin.startsWith('^') && new RegExp(item.origin).test(ancestorOrigin))
      );
      if (frameConfig) {
        let allowed = isAllow(frameConfig);
        Object.assign(config, {
          ...frameConfig,
          allowed,
        });

        if (frameConfig.path && parentPathname) {
          const pathFrameConfig = frameConfig.path.find((item) => item.pathname === parentPathname);
          if (pathFrameConfig) {
            allowed = isAllow(frameConfig);
            Object.assign(config, {
              ...pathFrameConfig,
              allowed,
            });
          }
        }
      }
    }
  } else {
    // 开发环境下允许嵌套
    config.allowed = true;
  }
  return config;
}

function isAllow(config: Config) {
  const { folders, dashboards } = config;
  const pathname = window.location.pathname;
  let allowed = true;
  // 嵌入 dashabords 时，只能访问 /d/:uid/:slug? 页面。(/login 除外)
  if ((folders?.length || dashboards?.length) && pathname !== '/login') {
    const matched = matchPath(pathname, { path: '/d/:uid/:slug?' });
    allowed = !!matched;
  }
  return allowed;
}

export function matchedFolder(folders: string[], folderUid?: string) {
  return folderUid ? folders.includes(folderUid) : true;
}

export function matchedDashboard(dashboards: string[], uid?: string) {
  return uid ? dashboards.includes(uid) : true;
}
