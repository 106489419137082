/** monitor-grafana
 * title支持grafana与monitor ui 同步
 * @author 冯凯妍 20221008
 */
export function observeTitle() {
  // 需要观察的节点
  const targetNode = document.querySelector('title')!;
  // 配置器的配置：需要监听的变动
  const config = { childList: true, characterData: true };
  // 变动时回调
  const callback = function (mutations: any[]) {
    window.parent.postMessage({ title: mutations[0].target.innerText }, '*');
  };
  // 创建一个MutationObserver实例
  const observer = new MutationObserver(callback);
  // 监听目标节点
  observer.observe(targetNode, config);
}
