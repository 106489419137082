/** monitor-grafana
 * 登录页
 * @author 梁宇健 20221008
 */
import { css } from '@emotion/css';
import React, { useEffect } from 'react';

import { getBackendSrv } from '@grafana/runtime';

import { setCookies } from './utils';

const containerStyles = css`
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function LoginPage() {
  useEffect(() => {
    function loginHandle(event: MessageEvent) {
      const { data, origin } = event;
      // 仅接收从白名单域名传递的事件
      // 不接收自己传递的事件
      if (!window.frameConfig.allowed || origin === window.location.origin) {
        return;
      }

      setCookies(data.cookies);
      getBackendSrv()
        .get('/monitor/login')
        .then(() => {
          const { pathname, search } = data.location;
          location.href = `${location.origin}${pathname}${search}`;
        })
        .catch((err) => console.error(err));
    }

    window.addEventListener('message', loginHandle);

    return () => {
      window.removeEventListener('message', loginHandle);
    };
  }, []);

  return (
    <div className={containerStyles}>
      <div className="preloader__enter">
        <div className="preloader__bounce">
          <div className="preloader__logo"></div>
        </div>
      </div>
      <div className="preloader__text">Loading Grafana</div>
    </div>
  );
}
