/**
 * monitor-grafana 添加auth 项目选择器 by liangyujian 20231115
 */
import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { ValuePicker, useStyles2 } from '@grafana/ui';

import { ProjectBaseProps, ProjectTeam } from './types';

export function ProjectPicker({ teams, onSelectChange }: ProjectBaseProps) {
  const styles = useStyles2(getStyles);
  return (
    <ValuePicker<ProjectTeam>
      aria-label="Change project"
      variant="secondary"
      buttonCss={styles.buttonCss}
      size="md"
      label=""
      fill="text"
      isFullWidth={false}
      options={teams.map((team) => ({
        label: team.name,
        value: team,
      }))}
      onChange={onSelectChange}
      icon="apps"
    />
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  buttonCss: css({
    color: theme.colors.text.secondary,
    '&:hover': {
      color: theme.colors.text.primary,
    },
  }),
});
