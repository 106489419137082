/**
 * monitor-grafana 添加auth 项目选择器 by liangyujian 20231115
 */
import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';

import { SelectableValue, GrafanaTheme2 } from '@grafana/data';
import { Icon, Select, useStyles2 } from '@grafana/ui';
import { getCookies, setCookies } from 'app/monitor/utils';

import { ProjectBaseProps, ProjectTeam } from './types';

export function ProjectSelect({ teams, onSelectChange }: ProjectBaseProps) {
  const styles = useStyles2(getStyles);
  const [value, setValue] = useState<SelectableValue<ProjectTeam>>(() => {
    // 兼容通过域名 ${project}.monitor-grafana.xxxx 获取项目
    const matched = window.location.host.match(/(\S*)\.monitor-grafana/);
    const projectCodeFromHost = matched?.[1];
    // 兼容通过url 参数获取项目
    const urlSearchParams = new URLSearchParams(window.location.search);
    const projectCodeFromSearch = urlSearchParams.get('project_code');

    /**
     * 1. 通过域名获取项目代号
     * 2. 通过url参数获取项目代号
     * 3. 通过全局变量获取项目代号
     * 4. 通过cookies获取项目代号
     * 5. 通过localStorage获取项目代号
     */
    const projectCode =
      projectCodeFromHost ||
      projectCodeFromSearch ||
      window.authProject ||
      getCookies('AUTH_PROJECT') ||
      localStorage.getItem('authProjectCode');

    // 存储项目代号
    if (projectCode) {
      window.authProject = projectCode;
      setCookies({ AUTH_PROJECT: projectCode });
      localStorage.setItem('authProjectCode', projectCode);
    }

    return {
      label: projectCode,
      value: { name: projectCode },
    };
  });
  const onChange = (option: SelectableValue<ProjectTeam>) => {
    setValue(option);
    onSelectChange(option);
  };

  useEffect(() => {
    if (!teams.length) {
      return;
    }
    const hasTeam = teams.some((team) => team.name === value?.value?.name);
    if (!value?.value?.name || !hasTeam) {
      const projectCode = teams[0].name;
      setValue({
        label: projectCode,
        value: { name: projectCode },
      });
      setCookies({ AUTH_PROJECT: projectCode });
      localStorage.setItem('authProjectCode', projectCode);
      window.authProject = projectCode;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  return (
    <Select<ProjectTeam>
      aria-label="Change project"
      width={'auto'}
      value={value}
      prefix={<Icon className="prefix-icon" name="apps" />}
      className={styles.select}
      options={teams.map((team) => ({
        label: team.name,
        value: team,
      }))}
      onChange={onChange}
    />
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  select: css({
    border: 'none',
    background: 'none',
    color: theme.colors.text.secondary,
    '&:hover': {
      color: theme.colors.text.primary,

      '& .prefix-icon': css({
        color: theme.colors.text.primary,
      }),
    },
  }),
});
