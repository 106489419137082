/** monitor-grafana
 * 共用配置文件
 * @author 冯凯妍 2022-10-25
 */
import Cookies, { CookieAttributes } from 'js-cookie';

export function getCookies(key: string) {
  return Cookies.get(key) || '';
}

export function setCookies(cookies: Record<string, string>) {
  if (!cookies) {
    return;
  }
  const options: CookieAttributes = {};
  if (window.location.protocol === 'https:') {
    options.sameSite = 'None';
    options.secure = true;
  }
  for (const key in cookies) {
    if (Object.prototype.hasOwnProperty.call(cookies, key)) {
      const value = cookies[key];
      Cookies.set(key, value, options);
    }
  }
}
