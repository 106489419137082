import { css } from '@emotion/css';
import React, { useCallback, useEffect, useState } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { isFetchError } from '@grafana/runtime';
import { Field, IconButton, Input, useStyles2, Text } from '@grafana/ui';

export interface Props {
  value: string;
  onEdit: (newValue: string) => Promise<void>;
  // monitor-grafana 是否 folder 类型 by liangyujian 20231228
  isFolder?: boolean;
}

// monitor-grafana 匹配项目前缀，格式：[项目代号]标题。 by liangyujian 20240115
const projectRegex = /^\[([^\[\]]+)\]/;

export const EditableTitle = ({ value, onEdit, isFolder }: Props) => {
  const styles = useStyles2(getStyles);
  const [localValue, setLocalValue] = useState(value);
  // monitor-grafana 编辑文件夹名时，需要移除前缀 by liangyujian 20240115
  const [inputValue, setInputValue] = useState(isFolder ? value.replace(projectRegex, '') : value);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  // monitor-grafana 编辑时，记录当前文件夹所属项目 by liangyujian 。
  const [currentProject, setCurrentProject] = useState<string>('');
  useEffect(() => {
    if (!isFolder) {
      return;
    }
    const matched = value.match(projectRegex);
    if (matched) {
      setCurrentProject(matched[1]);
    }
  }, [isFolder, value]);

  // sync local value with prop value
  useEffect(() => {
    setLocalValue(value);
    // monitor-grafana 如果是 folder ，编辑框移除项目前缀。 by liangyujian 20231228
    const inputValue = isFolder ? value.replace(projectRegex, '') : value;
    setInputValue(inputValue);
  }, [value, isFolder]);

  const onCommitChange = useCallback(
    async (event: React.FormEvent<HTMLInputElement>) => {
      // monitor-grafana folder 类型，需要加上前缀。 by liangyujian 20231228
      let newValue = event.currentTarget.value;
      if (isFolder) {
        newValue = `[${currentProject}]${newValue}`;
      }

      if (!newValue) {
        setErrorMessage('Please enter a title');
      } else if (newValue === value) {
        // no need to bother saving if the value hasn't changed
        // just clear any previous error messages and exit edit mode
        setErrorMessage(undefined);
        setIsEditing(false);
      } else {
        setIsLoading(true);
        try {
          await onEdit(newValue);
          setErrorMessage(undefined);
          setIsEditing(false);
        } catch (error) {
          if (isFetchError(error)) {
            setErrorMessage(error.data.message);
          } else if (error instanceof Error) {
            setErrorMessage(error.message);
          }
        }
        setIsLoading(false);
      }
    },
    [onEdit, value, isFolder, currentProject]
  );

  return !isEditing ? (
    <div className={styles.textContainer}>
      <div className={styles.textWrapper}>
        {/*
          use localValue instead of value
          this is to prevent the title from flickering back to the old value after the user has edited
          caused by the delay between the save completing and the new value being refetched
        */}
        <Text element="h1" truncate>
          {localValue}
        </Text>
        <IconButton name="pen" size="lg" tooltip="Edit title" onClick={() => setIsEditing(true)} />
      </div>
    </div>
  ) : (
    <div className={styles.inputContainer}>
      <Field className={styles.field} loading={isLoading} invalid={!!errorMessage} error={errorMessage}>
        <Input
          className={styles.input}
          // monitor-grafana 是否 folder 类型 by liangyujian 20231228
          defaultValue={inputValue}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onCommitChange(event);
            }
          }}
          // perfectly reasonable to autofocus here since we've made a conscious choice by clicking the edit button
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onBlur={onCommitChange}
          // monitor-grafana folder 类型，需要加上前缀。 by liangyujian 20231228
          onChange={(event) => {
            const value = event.currentTarget.value;
            const localValue = isFolder ? `[${currentProject}]${value}` : value;
            setLocalValue(localValue);
            setInputValue(value);
          }}
          onFocus={() => setIsEditing(true)}
          // monitor-grafana 是否 folder 类型 by liangyujian 20231228
          prefix={isFolder ? `[${currentProject}]` : ''}
        />
      </Field>
    </div>
  );
};

EditableTitle.displayName = 'EditableTitle';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    textContainer: css({
      minWidth: 0,
    }),
    field: css({
      flex: 1,
      // magic number here to ensure the input text lines up exactly with the h1 text
      // input has a 1px border + theme.spacing(1) padding so we need to offset that
      left: `calc(-${theme.spacing(1)} - 1px)`,
      position: 'relative',
      marginBottom: 0,
    }),
    input: css({
      input: {
        ...theme.typography.h1,
      },
    }),
    inputContainer: css({
      display: 'flex',
      flex: 1,
    }),
    textWrapper: css({
      alignItems: 'center',
      display: 'flex',
      gap: theme.spacing(1),
    }),
  };
};
